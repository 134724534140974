import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';

function Header() {
  return (
    <header>
      <nav>
        <div className="logo">
          <h1>Marten den Heijer | Software Engineer</h1>
        </div>
      </nav>
    </header>
  );
}

function HeroSection() {
  return (
    <section className="main" id="hero">
      <div className="container">
        <div className="center">
          <img src="martendenheijer.jpeg" alt="Profile Picture" />
        </div>
        <h1>Software Engineer</h1>
        <br />
        <p style={{ fontWeight: 700 }}>Strong in Delivering High-Quality, Sustainable Solutions</p>
        <br />

        <p>I am a software engineer with a strong focus on delivering high-quality, sustainable, and future-proof software solutions. My extensive knowledge of programming languages, frameworks, libraries, and code principles enables me to excel in providing top-notch software solutions.</p>
        <p>Collaboration and effective communication are among my greatest strengths. I thrive in team environments, working collaboratively to achieve shared goals. Serving as a lead developer at Qfact, I am adept at leading and guiding a team of software developers. Additionally, I find it incredibly rewarding to be able to share my knowledge and expertise with other developers.</p>
        <p>I am confident in my skills and always seek ways to further develop my expertise. I believe that by continuously learning and staying up-to-date with the latest advancements in the software industry, I can make valuable contributions to complex projects.</p>
        <p>In summary, as a software engineer, I am driven to deliver high-quality code and capable of working both independently and collaboratively. I am determined to contribute to successful software development and create innovative solutions.</p>
        <Link to="/portfolio">
          <button className="to-portfolio">View Portfolio</button>
        </Link>
      </div>
    </section>
  );
}

function SkillsSection() {
  return (
    <section id="skills">
      <div className="container">
        <h2>Skills</h2>
        <div className="category">
          <div>
            <p>I am a passionate software developer specializing in creating high-quality, customized software solutions. With my in-depth technical knowledge and expertise, I am able to convert complex problems into innovative and efficient solutions.</p>

            <p>However, my skills go beyond technical proficiency. I strongly believe in the power of effective communication and place great value on understanding the unique needs of my clients. By fostering open and transparent communication, I can closely collaborate with my team and stakeholders to deliver a product of excellent quality.</p>

            <p>Additionally, I am a confident professional who constantly challenges myself to grow and embrace new technologies. My curious mind and ability to quickly learn new concepts enable me to stay up-to-date with the latest developments in the software industry.</p>

            <p>As a leader in my field, I am capable of inspiring and motivating a team to achieve common goals. With my natural leadership skills and empathetic approach, I can create a positive and productive work environment where team members are encouraged to reach their full potential.</p>

            <p>I have knowledge of and experience with a wide range of technologies, including:</p>
            <ul>
              <li>Java, Maven</li>
              <li>C#, .NET, Unity</li>
              <li>Javascript, NodeJS, VueJS, React, Angular</li>
              <li>Python, Natural Language Toolkit (NLTK), Gensim, spaCy, Tensorflow, NumPy, Pandas, SciKit-Learn, PyTorch</li>
              <li>PHP, Laravel, Phalcon</li>
              <li>MySQL, Cassandra, MongoDB, Elasticsearch, Redis, Firebase, PostgreSQL</li>
              <li>Kubernetes, Cloud hosting (Google Cloud Hosting, DigitalOcean, Azure, AWS)</li>
              <li>Git, Github, Gitlab, Jenkins</li>
              <li>ChatGPT API</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

function ExperienceSection() {
  return (
    <section id="experience">
      <div className="container">
        <h2>Work Experience</h2>
        <div className="job">
          <h3>Lead Software Engineer at Qfact</h3>
          <p className="date">2022 - Present</p>
          <p>As a lead developer at Qfact, I am responsible for leading a team of software developers. My tasks include guiding the development process, making architectural decisions, and ensuring code quality. Additionally, I am involved in designing and implementing complex software solutions for our clients.</p>
        </div>
        <div className="job">
          <h3>Software Engineer at Qfact</h3>
          <p className="date">2018 - 2022</p>
          <p>As a software engineer at Qfact, I worked on various projects within web applications. I was responsible for developing and maintaining software solutions and collaborating with a team of developers to achieve project goals.</p>
        </div>
      </div>
    </section>
  );
}

function ContactSection() {
  return (
    <section id="contact">
      <div className="container">
        <h2>Contact</h2>
        <div className="">
          <p>
            <p>Email: <a href="mailto:mail@martendenheijer.nl">mail@martendenheijer.nl</a></p>
            <p>Phone: 06-29841575</p>
            <p>LinkedIn: <a href="https://www.linkedin.com/in/marten-den-heijer/" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/marten-den-heijer/</a></p>
          </p>
        </div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer>
      <div className="container">
        <p>&copy; 2023 Marten Den Heijer</p>
      </div>
    </footer>
  );
}

const ProjectContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
`;

const ProjectTitle = styled.div`
  background-color: #6CADDF;
  border-radius: 10px 10px 0 0;
  color: #fff;
  font-size: 1.5rem;
  padding: 10px;
`;

const ProjectDetails = styled.div`
  margin-top: 20px;
`;

const ProjectTechList = styled.ul`
  margin: 0 0 0 20px;
  padding: 0;
`;

const ProjectTechListItem = styled.li`
  margin-bottom: 5px;
`;

function Project({ title, date, description, technologies }) {

  return (
    <ProjectContainer>
      <ProjectTitle>{title}</ProjectTitle>
      <ProjectDetails>
        <p><strong>{date}</strong></p>
        <p>{description}</p>
        <p><strong>Technology overview:</strong></p>
        <ProjectTechList>
          {technologies.map((tech, index) => (
            <ProjectTechListItem key={index}>{tech}</ProjectTechListItem>
          ))}
        </ProjectTechList>
      </ProjectDetails>
    </ProjectContainer>
  );
}


function Portfolio() {
  const projects = [
    // {
    //   "title": "QIntelligence - Recommendations",
    //   "date": "May 2023 - September 2023",
    //   "description": "In my role on the QIntelligence Recommendations project, I focused on utilizing document information to provide users with project structure suggestions based on document content analysis. This involved assessing uploaded documents, identifying relevant themes within the text, and offering users suggestions for project updates based on the analysis. My responsibilities included researching optimal solutions, prioritizing accuracy in suggestions, optimizing AI and Large Language Models for text analysis, and ensuring the security of document content within the models. The final result was the successful development of a Python module that harnessed ChatGPT to preprocess document texts and deliver precise project recommendations.",
    //   "technologies": ["Python", "Elasticsearch", "Microservices", "Large Language Models", "ChatGPT API"]
    // },
    {
      "title": "QIntelligence - Document Indexing",
      "date": "December 2022 - May 2023",
      "description": "I led a project for Qfact aimed at indexing documents, marking the first step in a strategic initiative to harness user document information within existing software platforms. The project's goal was to establish an adaptable infrastructure capable of accommodating all document-related requirements. My responsibilities encompassed researching methods for storing and utilizing document information, designing the architecture and infrastructure, and overseeing implementation. The infrastructure's strength lay in its future-proof design, achieved through extensive stakeholder interviews and careful consideration of potential solutions. The project involved creating a Python service within the existing microservices architecture, utilizing an Elasticsearch database for document storage, and introducing user requirements for document uploading, indexing, and full-text search.",
      "technologies": ["Python", "Elasticsearch", "Microservices"]
    },
    {
      "title": "Analysis Functionality Enhancement",
      "date": "July 2022 - May 2023",
      "description": "As the leader of the Qfact Analysis Functionality project, I oversaw the complete project lifecycle, from initial functional design to successful release. This project was initiated due to the declining performance of existing analysis methods as data volumes increased. Collaborating with the software architect and senior developer, I played a pivotal role in designing the overall architecture and granular software modules. We implemented the project within a new service in our microservices architecture, using a MySQL database for frequent data synchronization. The innovative approach involved configuring analysis reports through a user-friendly interface, dynamically creating complex MySQL queries via cronjobs, and maintaining specially created MySQL reports tables. This design ensured rapid report loading while dealing with complex data spread across multiple microservices.",
      "technologies": ["NodeJS", "VueJS", "MySQL", "MongoDB"]
    },
    {
      "title": "Eventstore Development",
      "date": "August 2021 - December 2021",
      "description": "Working closely with the software architect, I contributed to the development of a new Eventstore for a cutting-edge Qfact software platform. The Eventstore served as a pivotal database for storing business logic in the Qfact platform, leveraging the Event Sourcing pattern for data storage. The choice of an Eventstore was driven by the need for enhanced accountability and auditing capabilities. SCRUM methodology guided our software development process. My responsibilities included designing the software, implementing the code, and facilitating migration and integration into the existing software platform. The Eventstore was built using JavaScript, with Cassandra for event storage and MongoDB for tracking read models.",
      "technologies": ["NodeJS", "Cassandra", "RabbitMQ", "MongoDB"]
    },
    {
      "title": "Car Sharing Mobile Application",
      "date": "May 2021 - August 2021",
      "description": "In this project, I developed a Mobile Car Sharing Application designed to manage shared vehicles efficiently. The application facilitated the tracking of car usage, notifying users about activity via push notifications and emails, and calculating mileage and fueling costs to ensure equitable cost-sharing among users. The application boasted a user-friendly design for intuitive interactions and used a straightforward architecture tailored to meet the project's requirements. The front-end was developed using VueJS, compiled into a mobile app, while the back-end was implemented in .NET, with data persistence handled by a MySQL database.",
      "technologies": [".NET", "MySQL", "VueJS", "Mobile App Development"]
    },
    {
      "title": "Collaborative Board Application",
      "date": "March 2021 - April 2021",
      "description": "During this project, I created a Collaborative Board Application that enabled users to collaborate seamlessly on a shared digital board. This platform allowed real-time synchronization of cards, text, videos, and images among all users, providing a clutter-free space for teams to share and document information effectively. To achieve this, I leveraged Socket technologies to synchronize changes across all users, ensuring a smooth collaborative experience. Additionally, I implemented a well-structured and modular Vue front-end, allowing for easy extensions and modifications. Notably, this Vue structure facilitated multiple users editing the same card concurrently without causing any unexpected issues. Furthermore, the NodeJS backend utilized eventstore technologies to optimize performance, minimize database queries, and reduce memory usage on both the client and server sides.",
      "technologies": ["NodeJS", "VueJS", "Eventstore", "MySQL", "Socket.io"]
    }
  ];

  return (
    <div className='portfolio'>
      <h1>Portfolio</h1>
      <div className="project-list">
        {projects.map((project, index) => (
          <Project key={index} {...project} />
        ))}
      </div>
    </div>
  );
}


function Home() {
  return (
    <div>
      <HeroSection />
      <SkillsSection />
      <ExperienceSection />
      <ContactSection />
    </div>
  );
}


function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
